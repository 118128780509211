import Image, { StaticImageData } from 'next/image';

export const HeroImage = ({
  image,
  altText,
  placeholder,
  priority,
  isColourBlockImage,
}: {
  image: StaticImageData | string;
  altText: string;
  placeholder?: 'blur' | 'empty' | undefined;
  priority?: boolean;
  isColourBlockImage?: boolean;
}): JSX.Element => {
  const ImageComponent = () => (
    <Image
      src={image}
      alt={altText}
      placeholder={placeholder}
      priority={priority}
      fill
      style={{
        objectFit: 'cover',
        objectPosition: 'bottom',
      }}
    />
  );

  return isColourBlockImage ? (
    <div className="mt-[35px] flex w-[100%] max-w-[650px] md:ml-[5%] md:mt-[24px]">
      <div className="relative w-[100%] overflow-hidden rounded-tl-[40px] pb-[100%]">
        <div className="absolute h-[100%] w-[100%]">
          <ImageComponent />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex w-[100%] max-w-[650px]">
      <div className="relative w-[100%] pb-[calc(100%_-_67px)]">
        <div className="absolute h-[100%] w-[100%]">
          <ImageComponent />
        </div>
      </div>
    </div>
  );
};
