import { BodyRegular } from '../../common/body';
import { H2, H4 } from '../../common/headline';

export const HeroText = ({
  title,
  subtitle,
  description,
  smallDescription,
  supertitle,
}: {
  supertitle?: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  description?: React.ReactNode;
  smallDescription?: boolean;
}): JSX.Element => (
  <div className="px-3 text-center md:px-0 md:text-left">
    {supertitle && (
      <div className="mb-1.5">
        <H4>{supertitle}</H4>
      </div>
    )}
    <H2>{title}</H2>
    {subtitle && (
      <div className="mt-2">
        <H4>{subtitle}</H4>
      </div>
    )}
    {description && (
      <div className={`${subtitle ? 'mt-4' : 'mt-6'}`}>
        {smallDescription ? (
          <BodyRegular>{description}</BodyRegular>
        ) : (
          <H4>{description}</H4>
        )}
      </div>
    )}
  </div>
);
