import { StaticImageData } from 'next/legacy/image';

import { NavBar } from '../NavBar/NavBar';
import { HeroText } from './HeroText';
import { HeroImage } from './HeroImage';

type Props = {
  image?: StaticImageData | string;
  altText?: string;
  color: string;
  supertitle?: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  description?: React.ReactNode;
  ctaChildren?: React.ReactNode;
  includeNav?: boolean;
  minHeight?: string;
  placeholder?: 'blur' | 'empty' | undefined;
  isColourBlockImage?: boolean;
  customContent?: React.ReactNode;
  topContent?: React.ReactNode;
  smallDescription?: boolean;
  /**
   * If true, will prioritise loading the image as per https://nextjs.org/docs/pages/api-reference/components/image#priority
   */
  priority?: boolean;
};

export const Hero = ({
  color,
  image,
  altText,
  supertitle,
  title,
  subtitle,
  description,
  ctaChildren,
  includeNav = true,
  placeholder,
  isColourBlockImage = false,
  customContent,
  topContent,
  smallDescription,
  priority,
}: Props): JSX.Element => (
  <>
    {includeNav && <NavBar />}
    <div className={`${color} pt-[64px]`}>
      {topContent}
      <div className="relative flex flex-col md:flex-row">
        <div className="mt-[16px] flex md:mt-0 md:w-[50%] md:pl-[40px] lg:pl-[60px]">
          <div className="h-full flex-1" />
          <div className="flex flex-col justify-center xl:max-w-[640px] xxl:max-w-[720px]">
            <HeroText
              supertitle={supertitle}
              title={title}
              subtitle={subtitle}
              description={description}
              smallDescription={smallDescription}
            />
            <div className="absolute bottom-[48px] z-10 flex w-full justify-center px-3 md:static md:mb-6 md:mt-[32px] md:justify-start md:px-0">
              {ctaChildren}
            </div>
          </div>
        </div>
        {customContent && <div>{customContent}</div>}
        {!!image && !!altText && (
          <div className="flex justify-end md:w-[50%]">
            <HeroImage
              image={image}
              altText={altText}
              placeholder={placeholder}
              isColourBlockImage={isColourBlockImage}
              priority={priority}
            />
          </div>
        )}
      </div>
    </div>
  </>
);
