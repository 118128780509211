type Props = {
  children: React.ReactNode;
  dataCy?: string;
};

export const BodyLarge = ({ children, dataCy }: Props): JSX.Element => (
  <p
    className="m-0 font-cera text-[18px] font-medium leading-[30px] tracking-[-0.22px] lg:text-[24px]"
    data-cy={dataCy}
  >
    {children}
  </p>
);

export const BodyRegular = ({ children, dataCy }: Props): JSX.Element => (
  <p
    className="m-0 font-cera text-[16px] font-normal leading-[20px] tracking-[-0.22px]"
    data-cy={dataCy}
  >
    {children}
  </p>
);

export const BodySmall = ({ children, dataCy }: Props): JSX.Element => (
  <p
    className="m-0 font-cera text-[14px] font-normal leading-[17px] tracking-[-0.22px] lg:leading-[20px]"
    data-cy={dataCy}
  >
    {children}
  </p>
);
